// extracted by mini-css-extract-plugin
export var socialContainer = "w_rX";
export var instagramLink = "w_rY";
export var socialWrapperLeft = "w_nn d_dB d_Y d_by d_bH d_bn d_bF";
export var socialContentContainer = "w_rZ";
export var socialContentContainerFull = "w_r0";
export var instagramContainer = "w_r1";
export var twitterContainer = "w_r2";
export var facebookContainer = "w_r3";
export var socialErrorContainer = "w_r4";
export var facebookContainerWide = "w_r5";
export var twitterContainerWide = "w_r6";
export var socialParagraphCenter = "w_r7";
export var instaWrapper = "w_r8";
export var SubtitleSmall = "w_pH B_pH B_sq B_sC";
export var SubtitleNormal = "w_pJ B_pJ B_sq B_sD";
export var SubtitleLarge = "w_pK B_pK B_sq B_sF";