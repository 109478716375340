// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "C_fQ d_fQ d_by d_bG d_bH";
export var navbarDividedRight = "C_fR d_fR d_by d_bH";
export var menuDesign6 = "C_tG d_fM d_by d_dv d_bN d_bH";
export var menuDesign7 = "C_tH d_fM d_by d_dv d_bN d_bH";
export var menuRight = "C_tJ d_fM d_by d_dv d_bN d_bH";
export var menuLeft = "C_tK d_fM d_by d_dv d_bN d_bH";
export var menuCenter = "C_tL d_fN d_fM d_by d_dv d_bN d_v d_bC d_bH";
export var menuDivided = "C_p6 d_fN d_fM d_by d_dv d_bN d_v d_bC";
export var menuDesign5 = "C_qh d_fP d_fM d_by d_dv d_bN d_bH";
export var isBurger = "C_tM";
export var navbarItem = "C_p7 d_bw";
export var navbarLogoItemWrapper = "C_fY d_fY d_bB d_bN";
export var burgerToggleVisibleOpen = "C_tN d_gd d_bx d_Y d_br";
export var burgerToggleVisible = "C_tP d_gd d_bx d_Y d_br";
export var burgerToggle = "C_tQ d_gd d_bx d_Y d_br d_Y";
export var burgerToggleOpen = "C_tR d_gd d_bx d_Y d_br";
export var burgerInput = "C_tS d_f2 d_v d_G d_bx d_cb d_dk d_bc d_Z d_7 d_6 d_3 d_8";
export var burgerIcon = "C_tT d_f3 d_v d_G";
export var burgerLine = "C_tV d_f1";
export var burgerMenuDesign6 = "C_tW d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign7 = "C_tX d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuLeft = "C_tY d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign5 = "C_tZ d_f8 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuRight = "C_t0 d_f8 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuCenter = "C_t1 d_f9 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDivided = "C_t2 d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var secondary = "C_t3 d_bB d_bN";
export var staticBurger = "C_t4";
export var menu = "C_t5";
export var navbarDividedLogo = "C_t6";
export var nav = "C_t7";