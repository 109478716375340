// extracted by mini-css-extract-plugin
export var customText = "r_rf d_dt d_cr d_cf";
export var videoIframeStyle = "r_pk d_d4 d_v d_G d_bx d_b0 d_Q";
export var customImageWrapper = "r_rg d_cr d_cf d_Y";
export var customRow = "r_pF d_bC d_bd";
export var quoteWrapper = "r_gQ d_bB d_bN d_cr d_cf";
export var quoteBar = "r_pp d_G";
export var masonryImageWrapper = "r_pw";
export var title = "r_rh";
export var Title3Small = "r_rj B_rj B_sq B_sy";
export var Title3Normal = "r_rk B_rk B_sq B_sz";
export var Title3Large = "r_rl B_rl B_sq B_sB";