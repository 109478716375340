// extracted by mini-css-extract-plugin
export var lbContainerOuter = "D_t8";
export var lbContainerInner = "D_t9";
export var movingForwards = "D_vb";
export var movingForwardsOther = "D_vc";
export var movingBackwards = "D_vd";
export var movingBackwardsOther = "D_vf";
export var lbImage = "D_vg";
export var lbOtherImage = "D_vh";
export var prevButton = "D_vj";
export var nextButton = "D_vk";
export var closing = "D_vl";
export var appear = "D_vm";