// extracted by mini-css-extract-plugin
export var carouselContainer = "G_vq d_v d_G d_bd d_Y";
export var carouselContainerCards = "G_vr G_vq d_v d_G d_bd d_Y";
export var carouselContainerSides = "G_vs d_v d_G d_Y";
export var prevCarouselItem = "G_vt d_v d_G d_Z d_j";
export var prevCarouselItemL = "G_vv G_vt d_v d_G d_Z d_j";
export var moveInFromLeft = "G_vw";
export var prevCarouselItemR = "G_vx G_vt d_v d_G d_Z d_j";
export var moveInFromRight = "G_vy";
export var selectedCarouselItem = "G_vz d_v d_G d_Y d_bd";
export var selectedCarouselItemL = "G_vB G_vz d_v d_G d_Y d_bd";
export var selectedCarouselItemR = "G_vC G_vz d_v d_G d_Y d_bd";
export var nextCarouselItem = "G_vD d_v d_G d_Z d_j";
export var nextCarouselItemL = "G_vF G_vD d_v d_G d_Z d_j";
export var nextCarouselItemR = "G_vG G_vD d_v d_G d_Z d_j";
export var arrowContainer = "G_vH d_G d_Z d_dz d_bk d_3 d_b0";
export var prevArrowContainer = "G_vJ G_vH d_G d_Z d_dz d_bk d_3 d_b0";
export var prevArrowContainerHidden = "G_vK G_vJ G_vH d_G d_Z d_dz d_bk d_3 d_b0 d_j";
export var nextArrowContainer = "G_vL G_vH d_G d_Z d_dz d_bk d_3 d_b0";
export var carouselContainerProducts = "G_vM";
export var nextArrowContainerHidden = "G_vN G_vL G_vH d_G d_Z d_dz d_bk d_3 d_b0 d_j";
export var arrow = "G_kH d_Z";
export var prevArrow = "G_vP G_kH d_Z";
export var nextArrow = "G_vQ G_kH d_Z";
export var carouselIndicatorContainer = "G_vR d_v d_dv d_bk d_by d_bC d_bN d_bH d_Z";
export var btnsContainer = "G_vS d_v d_by d_bD";
export var carouselText = "G_vT d_dp d_by d_bC d_bN d_b0 d_dz d_cC";
export var carouselTextInactive = "G_vV G_vT d_dp d_by d_bC d_bN d_b0 d_dz d_cC";
export var indicator = "G_vW d_b6";
export var carouselIndicator = "G_vX G_vW d_b6";
export var carouselIndicatorSelected = "G_vY G_vW d_b6";
export var arrowsContainerTopRight = "G_vZ d_Z d_bk d_bB";
export var arrowsContainerBottomLeft = "G_v0 d_Z d_bk d_bB";
export var arrowsContainerSides = "G_v1 d_Z d_bk d_bB";
export var smallArrowsBase = "G_v2 d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainer = "G_v3 G_v2 d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainerPrev = "G_v4 G_v3 G_v2 d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainerSmall = "G_v5 G_v2 d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainerPrevSmall = "G_v6 G_v5 G_v2 d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var icon = "G_pD";
export var iconSmall = "G_v7";
export var multipleWrapper = "G_v8 d_bB d_bD d_bd";
export var multipleImage = "G_v9 d_bB";
export var sidesPrevContainer = "G_wb G_v3 G_v2 d_bS d_by d_bC d_bN d_b0 d_dz d_cC d_fm d_Z d_bk d_cC";
export var sidesNextContainer = "G_wc G_v3 G_v2 d_bS d_by d_bC d_bN d_b0 d_dz d_cC d_fm d_Z d_bk d_cC";